import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import StaticPageContent from "components/StaticPageContent";

const PrivacyPolicy = ({ data }) => {
  const content = data?.privacyPolicy?.nodes?.[0]?.content;

  return (
    <Layout
      activeHeader
      seo={{
        title: "Polityka prywatności",
        // description: seo?.metaDesc || "",
      }}
    >
      <StaticPageContent content={content} />
    </Layout>
  );
};

export const query = graphql`
  query {
    privacyPolicy: allWpPage(filter: { id: { eq: "cG9zdDoyMQ==" } }) {
      nodes {
        title
        content
      }
    }
  }
`;

export default PrivacyPolicy;
